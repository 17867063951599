<template>
  <div>
    <el-button
        class="button-over"
        type="success"
        @click="createNewRole()"
    >
      <i class="el-icon-plus"/>
      Tạo mới quyền api
    </el-button>

    <!--    <el-tabs type="card" @tab-click="handleClickTab">-->
    <!--      <el-tab-pane label="Danh sách api"-->
    <!--                   name="apiTotalListTab"-->


    <!--      >-->
    <el-table
        ref="tableRef1"
        highlight-current-row
        :data="listFilterRow1()"
        :cell-style="tableRowStyle1"
        :header-cell-style="tableHeaderColor1"
        :max-height="$tableMaxHeight"
        @selection-change="handleSelectionChange1"
        border
    >
      <el-table-column
          type="selection"
          align="center"
          width="55"
      ></el-table-column>
      <el-table-column
          type="index"
          label="STT"
          width="50"
          align="center"
      ></el-table-column>
      <el-table-column
          prop="apiName"
          label="Tên quyền"
          width="250"
      ></el-table-column>

      <el-table-column
          prop="apiUrl"
          label="Api"
          width="250"
      ></el-table-column>

      <el-table-column
          align="center"
          width="65"
          prop="level1"
          label="level 1"
      ></el-table-column>

      <el-table-column
          align="center"
          width="65"
          prop="level2"
          label="level 2"
      ></el-table-column>

      <el-table-column
          align="center"
          width="65"
          prop="level3"
          label="level 3"
      ></el-table-column>

      <el-table-column
          prop="apiDescription"
          label="Mô tả"
      ></el-table-column>

      <el-table-column
          label="Tác vụ"
          width="250"
          align="center">
        <template slot-scope="scope">

          <el-button
              v-if="scope.row.expandRow"
              size="mini"
              type="primary"
              @click="toogleExpandDetailDate1(scope.row)"
          >Thu nhỏ</el-button>

          <el-button
              v-else
              type="warning"
              size="mini"
              @click="toogleExpandDetailDate1(scope.row)"
          >Mở rộng</el-button>

          <el-button
              type="success"
              size="mini"
              @click="updateApiMethod(scope.row)"
          >Sửa</el-button>

          <el-button
              type="danger"
              size="mini"
              @click="deleteApiMethod(scope.row)"
          >Xóa</el-button>

        </template>
      </el-table-column>

      <el-table-column type="expand" width="1">

        <template slot-scope="scope">
          <div
              style="text-align: center; color: orange"
              v-if="listFilterRow2(scope).length === 0"
          >Không có</div>
          <el-table
              v-else
              ref="tableRef2"
              :data="listFilterRow2(scope)"
              :cell-style="tableRowStyle2"
              :header-cell-style="tableHeaderChidrenColor2"
              @selection-change="handleSelectionChange2"
              border>

            <el-table-column
                type="selection"
                align="center"
                width="55"
            ></el-table-column>

            <el-table-column
                type="index"
                label="STT"
                width="50"
                align="center"
            ></el-table-column>

            <el-table-column
                prop="apiName"
                label="Tên quyền"
                width="250"
            ></el-table-column>

            <el-table-column
                prop="apiUrl"
                label="Api"
                width="250"
            ></el-table-column>

            <el-table-column
                align="center"
                width="65"
                prop="level1"
                label="level 1"
            ></el-table-column>

            <el-table-column
                align="center"
                width="65"
                prop="level2"
                label="level 2"
            ></el-table-column>

            <el-table-column
                align="center"
                width="65"
                prop="level3"
                label="level 3"
            ></el-table-column>

            <el-table-column
                prop="apiDescription"
                label="Mô tả"
            ></el-table-column>

            <el-table-column label="Tác vụ" width="250" align="center" >
              <template slot-scope="scope">
                <el-button
                    v-if="scope.row.expandRow1"
                    size="mini"
                    type="primary"
                    @click="toogleExpandDetailDate2(scope.row)"
                >Thu nhỏ
                </el-button>
                <el-button
                    v-else
                    type="warning"
                    size="mini"
                    @click="toogleExpandDetailDate2(scope.row)"
                >Mở rộng</el-button>

                <el-button
                    type="success"
                    size="mini"
                    @click="updateApiMethod(scope.row)"
                >Sửa</el-button>

                <el-button
                    type="danger"
                    size="mini"
                    @click="deleteApiMethod(scope.row)"
                >Xóa</el-button>

              </template>
            </el-table-column>

            <el-table-column type="expand" width="1">
              <template slot-scope="scope">
                <div
                    style="text-align: center; color: orange"
                    v-if="listFilterRow3(scope).length === 0"
                >Không có</div>
                <el-table
                    v-else
                    :data="listFilterRow3(scope)"
                    :header-cell-style="tableHeaderChidrenColor3"
                    @selection-change="handleSelectionChange3"
                    border
                >
                  <el-table-column
                      type="selection"
                      align="center"
                      width="55"
                  ></el-table-column>

                  <el-table-column
                      type="index"
                      label="STT"
                      width="50"
                      align="center"
                  ></el-table-column>

                  <el-table-column
                      prop="apiName"
                      label="Tên quyền"
                      width="250"
                  ></el-table-column>

                  <el-table-column
                      prop="apiUrl"
                      label="api"
                      width="250"
                  ></el-table-column>

                  <el-table-column
                      align="center"
                      width="65"
                      prop="level1"
                      label="level 1"
                  ></el-table-column>

                  <el-table-column
                      align="center"
                      width="65"
                      prop="level2"
                      label="level 2"
                  ></el-table-column>

                  <el-table-column
                      align="center"
                      width="65"
                      prop="level3"
                      label="level 3"
                  ></el-table-column>

                  <el-table-column
                      prop="apiDescription"
                      label="Mô tả"
                  ></el-table-column>

                  <el-table-column
                      label="Tác vụ"
                      width="250"
                      align="center">
                    <template slot-scope="scope">
                      <el-button
                          type="success"
                          size="mini"
                          @click="updateApiMethod(scope.row)"
                      >Sửa</el-button>

                      <el-button
                          type="danger"
                          size="mini"
                          @click="deleteApiMethod(scope.row)"
                      >Xóa</el-button>
                    </template>
                  </el-table-column>

                </el-table>

              </template>
            </el-table-column>
          </el-table>

        </template>
      </el-table-column>
    </el-table>
    <!--      </el-tab-pane>-->
    <!--    </el-tabs>-->

    <div style="float: right; margin: 15px 0">
      <el-button class="button-bottom" type="success" @click="updateRanksMethod()"
      >Sắp xếp</el-button>
    </div>

    <AddNewRole
        :dialogVisible="showCreateRole"
        @dialog-close="dialogCloseButton"
    />
    <UpdateApiRole
        :dialogVisible="showUpdateApiRoleDialog"
        :updateApiRole="updateApiRole"
        @dialog-close="dialogCloseUpdateButton"
    />
  </div>
</template>
<script>
import SystemConfigService from "@/services/AdminService/SystemConfigService";
import AddNewRole from "@/views/schoolconfig/roleApi/AddNewRole.vue";
import UpdateApiRole from "@/views/schoolconfig/roleApi/UpdateApiRole.vue";

export default {
  components: {
    AddNewRole,
    UpdateApiRole,
  },
  data() {
    return {
      dataSearch: {
        type: "plus",
      },
      showUpdateDialog: false,
      showCreateRole: false,
      showUpdateApiRoleDialog: false,
      multipleSelection: [],
      responseList: [],
      updateApiRole: {
        id: "",
        apiName: "",
        apiDescription: "",
        apiUrl: "",
      },

    };
  },
  computed: {},
  methods: {
    createNewRole() {
      this.showCreateRole = true;
    },
    dialogCloseButton() {
      this.showCreateRole = false;
      this.fetchData();
    },
    // Danh sách lọc role API có level1 = 0 (các api chính)
    listFilterRow1(){
      const listFilterRow1 = this.responseList.filter(
          (x) =>
              x.level1 !== 0 &&
              x.level2 === 0 &&
              x.level3 === 0)
      return listFilterRow1;
    },
    // Danh sách lọc role API có level2 !=0 (các api trực thuộc api chính tấng 2 )
    listFilterRow2(scope) {
      const listFilterRow2 = this.responseList.filter(
          (x) =>
              x.level1 === scope.row.level1 &&
              x.level2 !== 0 &&
              x.level3 === 0
      )
      return listFilterRow2;
    },
    // Danh sách lọc role API có level3 !=0 (các api trực thuộc api tầng 3 )
    listFilterRow3(scope) {
      const listFilterRow3 = this.responseList.filter(
          (x) =>
              x.level1 === scope.row.level1 &&
              x.level2 === scope.row.level2 &&
              x.level3 !== 0
      )
      return listFilterRow3;
    },
    tableHeaderColor1() {
      return "background-color: #78a5e7;color: #fff;font-weight: bold;";
    },
    tableHeaderChidrenColor2() {
      return "background-color: rgb(116 191 172);color: #606266;";
    },
    tableHeaderChidrenColor3() {
      return "background-color: rgb(177 204 8);color: #606266;";
    },
    tableRowStyle1({row}) {
      if (row.expandRow) {
        return "font-weight: bold";
      }
    },
    tableRowStyle2({row}) {
      if (row.expandRow1) {
        return "font-weight: bold";
      }
    },
    tableRowStyle3({row}) {
      if (row.expandRow2) {
        return "font-weight: bold";
      }
    },
    deleteApiMethod(row) {
      console.log('rowDelete', row)
      this.$confirm(
          "Bạn có chắc chắn muốn xóa quyền này?",
          "Thông báo!",
          {
            distinguishCancelAndClose: true,
            closeOnClickModal: false,
            confirmButtonText: "Có",
            cancelButtonText: "Không",
          }
      ).then(() => {
        SystemConfigService.deleteApiRole(row.id)
            .then((resp) => {
              console.log(resp)
              this.$message({
                message: "Xóa dữ liệu thành công!",
                type: "success",
              });
              this.fetchData();
            })
            .catch((err) => {
              this.$message({
                message: err.response.data.message,
                type: "error",
              });
            });
      });
    },
    handleSelectionChange1(val) {
      this.multipleSelection = val;
    },
    handleSelectionChange2(val) {
      this.multipleSelection = val;
    },
    handleSelectionChange3(val) {
      this.multipleSelection = val;
    },
    toogleExpandDetailDate1(row) {
      row.expandRow = !row.expandRow;
      let $tableRef1 = this.$refs.tableRef1;
      $tableRef1.toggleRowExpansion(row);
    },
    toogleExpandDetailDate2(row) {
      console.log('row3', row)
      row.expandRow1 = !row.expandRow1;
      let $tableRef2 = this.$refs.tableRef2;
      $tableRef2.toggleRowExpansion(row);
    },
    toogleExpandDetailDate3(row) {
      console.log('row3', row)
      row.expandRow2 = !row.expandRow2;
      let $tableRef3 = this.$refs.tableRef3;
      $tableRef3.toggleRowExpansion(row);
    },

    dialogCloseUpdateButton() {
      this.showUpdateApiRoleDialog = false;
      this.fetchData();
    },
    closeUpdateDialogMethod() {
      this.showUpdateDialog = false;
      this.fetchData();
    },

    updateApiMethod(row) {
      console.log("updateApiMethod", row)
      this.updateApiRole.id = row.id;
      this.updateApiRole.apiName = row.apiName;
      this.updateApiRole.apiUrl = row.apiUrl;
      this.updateApiRole.apiDescription = row.apiDescription;
      this.showUpdateApiRoleDialog = true;
    },
    updateRanksMethod() {
      let selectRowList = this.multipleSelection;
      if (selectRowList.length != 2) {
        this.$message({
          message: "Chọn 2 quyền để thực hiện sắp xếp",
          type: "error",
        });
        return;
      }
      let dataInput = {
        id1: selectRowList[0].id,
        id2: selectRowList[1].id,
      };
      SystemConfigService.updateApiRanks(dataInput)
          .then((resp) => {
            this.$message({
              message: resp.data.message,
              type: "success",
            });
            this.fetchData();
          })
          .catch((err) => {
            this.$message({
              message: err.response.data.message,
              type: "error",
            });
          });
    },
    fetchData() {
      SystemConfigService.getApi(this.dataSearch.type)
          .then((resp) => {
            this.responseList = resp.data.data;
          })
          .catch((err) => {
            this.$message({
              message: err.response.data.message,
              type: "error",
            });
          });
    },
  },
  beforeMount() {
    this.fetchData();
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-table__expanded-cell {
  padding: 0px 0px;
}

/deep/ .el-table th {
  padding: 6px 0;
}

.button-bottom {
  border-radius: 0;
  margin-left: 3px;
}
</style>
