<template>
  <el-dialog
      title="Cập nhật api role"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="closeDialog"
      :close-on-click-modal="false"
  >
    <el-form
        label-width="120px"
        :model="updateApiRole"
        label-position="left"
        :rules="rules"
        ref="updateApiRole"
    >
      <el-form-item label="Tên api" prop="apiName">
        <el-input
            v-model="updateApiRole.apiName"
            placeholder="Nhập tên api"
        ></el-input>
      </el-form-item>

      <el-form-item label="Tên đường dẫn " prop="apiUrl">
        <el-input
            v-model="updateApiRole.apiUrl"
            placeholder="Nhập tên api"
        ></el-input>
      </el-form-item>

      <el-form-item label="Mô tả api" prop="apiDescription">
        <el-input
            :rows="1"
            type="textarea"
            autosize
            v-model="updateApiRole.apiDescription"
            placeholder="Nhập mô tả"
        ></el-input>
      </el-form-item>

    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button
          type="danger"
          size="medium"
          @click="closeDialogByButton('updateApiRole')"
      >
        <i class="el-icon-circle-close" />
        <span>Đóng</span>
      </el-button>
      <el-button
          type="success"
          size="medium"
          :loading="loadingButton"
          mini
          @click="submitForm('updateApiRole')"
      >
        <i class="el-icon-circle-check" />
        <span>Lưu</span>
      </el-button>
    </span>
  </el-dialog>
</template>

<script>

import SystemConfigService from "@/services/AdminService/SystemConfigService";

export default {

  props: {
    dialogVisible: null,
    updateApiRole: null,
  },
  data() {
    return {
      loadingButton: false,
      rules: {
        apiUrl: [
          {
            required: true,
            message: "Không được để trống đường dẫn",
            trigger: "blur",
          },
        ],
        apiName: [
          {
            required: true,
            message: "Không được để trống tên api",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    //reset when click x
    closeDialog() {
      this.$refs["updateApiRole"].resetFields();
      this.$emit("dialog-close");
    },
    //click when click button
    closeDialogByButton(fromData) {
      this.$refs[fromData].resetFields();
      this.$emit("dialog-close");
    },
    submitForm(fromData) {
      this.$refs[fromData].validate((valid) => {
        if (valid) {
          this.loadingButton = false;
          SystemConfigService.updateApiRole(this.updateApiRole.id, this.updateApiRole)
              .then((resp) => {
                console.log(resp.data)
                this.$message({
                  message: "Thay đối thành công!",
                  type: "success",
                });
                this.closeDialogByButton(fromData);
              })
              .catch((err) => {
                console.log(err)
                this.$message({
                  message: err.response.data.message,
                  type: "error",
                });
              })
        } else {
          console.log("error validate data!");
          return false;
        }
      });
    },
  },
}
</script>

<style scoped>

</style>