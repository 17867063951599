var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Thêm mới quyền",
        visible: _vm.dialogVisible,
        width: "40%",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "newRole",
          attrs: {
            "label-width": "120px",
            model: _vm.newRole,
            "label-position": "left",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Tên quyền", prop: "apiName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "Nhập tên quyền " },
                model: {
                  value: _vm.newRole.apiName,
                  callback: function ($$v) {
                    _vm.$set(_vm.newRole, "apiName", $$v)
                  },
                  expression: "newRole.apiName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "URL", prop: "apiUrl" } },
            [
              _c("el-input", {
                attrs: { placeholder: "Nhập đường dẫn" },
                model: {
                  value: _vm.newRole.apiUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.newRole, "apiUrl", $$v)
                  },
                  expression: "newRole.apiUrl",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Sắp xếp level " } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticClass: "custom-input", attrs: { span: 5 } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Level 1" },
                        on: {
                          input: function ($event) {
                            return _vm.validateNumberInput("level1")
                          },
                        },
                        model: {
                          value: _vm.newRole.level1,
                          callback: function ($$v) {
                            _vm.$set(_vm.newRole, "level1", $$v)
                          },
                          expression: "newRole.level1",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticClass: "custom-input", attrs: { span: 5 } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Level 2" },
                        on: {
                          input: function ($event) {
                            return _vm.validateNumberInput("level2")
                          },
                        },
                        model: {
                          value: _vm.newRole.level2,
                          callback: function ($$v) {
                            _vm.$set(_vm.newRole, "level2", $$v)
                          },
                          expression: "newRole.level2",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticClass: "custom-input", attrs: { span: 5 } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Level 3" },
                        on: {
                          input: function ($event) {
                            return _vm.validateNumberInput("level3")
                          },
                        },
                        model: {
                          value: _vm.newRole.level3,
                          callback: function ($$v) {
                            _vm.$set(_vm.newRole, "level3", $$v)
                          },
                          expression: "newRole.level3",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("span", [
                _vm._v(
                  "Hiện tại quyền api có level cao nhất là: " +
                    _vm._s(_vm.apiHasMaxLevel1.level1)
                ),
              ]),
              _c("br"),
              _c("span", [
                _vm._v("Tên api: " + _vm._s(_vm.apiHasMaxLevel1.apiName)),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Mô tả", prop: "apiDescription" } },
            [
              _c("el-input", {
                attrs: {
                  rows: 1,
                  type: "textarea",
                  autosize: "",
                  placeholder: "Nhập mô tả",
                },
                model: {
                  value: _vm.newRole.apiDescription,
                  callback: function ($$v) {
                    _vm.$set(_vm.newRole, "apiDescription", $$v)
                  },
                  expression: "newRole.apiDescription",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialogByButton("newRole")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v("Đóng")]),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                size: "medium",
                loading: _vm.loadingButton,
                mini: "",
              },
              on: {
                click: function ($event) {
                  return _vm.submitForm("newRole")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-check" }),
              _c("span", [_vm._v("Lưu")]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }