<template>
  <el-dialog
      title="Thêm mới quyền"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="closeDialog"
      :close-on-click-modal="false"
  >
    <el-form
        label-width="120px"
        :model="newRole"
        label-position="left"
        :rules="rules"
        ref="newRole"
    >
      <el-form-item label="Tên quyền" prop="apiName">
        <el-input
            v-model="newRole.apiName"
            placeholder="Nhập tên quyền "
        ></el-input>
      </el-form-item>
      <el-form-item label="URL" prop="apiUrl">
        <el-input
            v-model="newRole.apiUrl"
            placeholder="Nhập đường dẫn"
        ></el-input>

      </el-form-item>

      <el-form-item label="Sắp xếp level ">
        <el-row>
          <el-col :span="5" class="custom-input">
            <el-input
                @input="validateNumberInput('level1')"
                v-model="newRole.level1"
                placeholder="Level 1"
            ></el-input>
          </el-col>

          <el-col :span="5"  class="custom-input" >
            <el-input
                @input="validateNumberInput('level2')"
                v-model="newRole.level2"
                placeholder="Level 2"
            ></el-input>
          </el-col>
          <el-col :span="5" class="custom-input">
            <el-input
                @input="validateNumberInput('level3')"
                v-model="newRole.level3"
                placeholder="Level 3"
            ></el-input>
          </el-col>
        </el-row>
        <span>Hiện tại quyền api có level cao nhất là: {{apiHasMaxLevel1.level1}}</span><br>
        <span>Tên api: {{apiHasMaxLevel1.apiName}}</span>
      </el-form-item>

      <el-form-item label="Mô tả" prop="apiDescription">
        <el-input
            :rows="1"
            type="textarea"
            autosize
            v-model="newRole.apiDescription"
            placeholder="Nhập mô tả"
        ></el-input>
      </el-form-item>

    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button
          type="danger"
          size="medium"
          @click="closeDialogByButton('newRole')"
      >
        <i class="el-icon-circle-close"/>
        <span>Đóng</span>
      </el-button>
      <el-button
          type="success"
          size="medium"
          :loading="loadingButton"
          mini
          @click="submitForm('newRole')"
      >
        <i class="el-icon-circle-check"/>
        <span>Lưu</span>
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import SystemConfigService from "@/services/AdminService/SystemConfigService";

export default {
  name: "AddNewRole",
  props: {
    dialogVisible: null,
  },
  data() {
    return {
      newRole: {
        apiName: "",
        apiDescription: "",
        apiUrl: "",
        type: "plus",
        level1: "",
        level2: "",
        level3: "",
      },
      apiHasMaxLevel1:{},
      items: [
        {label: 'Nhà trường', value: "plus"},
        {label: 'Giáo viên', value: "teacher"},
        // Thêm các mục khác tại đây
      ],
      loadingButton: false,
      rules: {
        apiUrl: [
          {
            required: true,
            message: "Không được để trống đường dẫn",
            trigger: "blur",
          },
        ],
        apiName: [
          {
            required: true,
            message: "Không được để trống tên api",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {

  },
  methods: {
    closeDialog() {
      this.$refs["newRole"].resetFields();
      this.$emit("dialog-close");
    },
    //click when click button
    closeDialogByButton(fromData) {
      this.$refs[fromData].resetFields();
      this.$emit("dialog-close");
    },
    // chỉ cho nhạp số, ko cho nhập chữ
    validateNumberInput(prop) {
      console.log(prop)
      if(prop === 'level1'){
        this.newRole.level1 = this.newRole.level1.replace(/[^0-9]/g, '')
      }else if(prop === 'level2'){
        this.newRole.level2 = this.newRole.level2.replace(/[^0-9]/g, '')
      }else if(prop === 'level3'){
        this.newRole.level3 = this.newRole.level3.replace(/[^0-9]/g, '');
      }
    },
    clearCreateApi(){
          this.newRole.apiDescription= "",
          this.newRole.apiName= "",
          this.newRole.apiUrl= "",
          this.newRole.level1= "",
          this.newRole.level2= "",
          this.newRole.level3= ""
    },
    // lấy 1 api có ranks và level1 cao nhất
    getMaxLevel1(){
      SystemConfigService.getMaxLevel1()
          .then((resp)=>{
            this.apiHasMaxLevel1 = resp.data;
          }).catch((err)=>{
        console.log(err)
      })
    },
    // Gửi dữ liệu lên serve tạo mơi api
    submitForm(fromData) {
      this.$refs[fromData].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          SystemConfigService.addNewRole(this.newRole)
              .then(() => {
                this.$message({
                  message: "Tạo mới thành công!",
                  type: "success",
                });
                this.clearCreateApi();
                this.getMaxLevel1();
                this.closeDialogByButton(fromData);
              })
              .catch((err) => {
                console.log(err)
                this.$message({
                  message: "Tạo quyền mới không thành công, đường dẫn đã tồn tại!",
                  type: "error",
                });
              })
        } else {
          console.log("error validate data!");
          return false;
        }
        this.loadingButton = false;
      });
    },
  },
  beforeMount() {
    this.getMaxLevel1();
  }
}
</script>

<style scoped>
.custom-input {

  border-radius: 5px; /* Đặt góc bo viền */
  padding: 3px;
  margin: 5px;
}
</style>